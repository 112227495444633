const poster = async (url: string, token: string | null, data: any) => {
  if (!token) {
    throw new Error("Token is not available");
  }

  const newUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST + url;

  try {
    const res = await fetch(newUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    // Check if the response status is 200 or 201 (common for POST requests)
    if (res.status === 200 || res.status === 201) {
      // If the response is empty, return a success message
      if (res.headers.get('content-length') === '0') {
        return { success: true, message: "Data posted successfully" };
      }

      // If there's content and it's JSON, parse it
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const text = await res.text();
        return text ? JSON.parse(text) : { success: true, message: "Data posted successfully" };
      }

      // If there's content but it's not JSON, return it as text
      return { success: true, message: await res.text() };
    }

    // If the status is not 200 or 201, throw an error
    throw new Error(`Error posting data: ${res.status} ${res.statusText}`);
  } catch (error) {
    console.error("Error posting data", error);
    throw error;
  }
};

const fetchToken = async () => {
  try {
    const res = await fetch("/api/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`Error fetching access token: ${res.statusText}`);
    }

    const data = await res.json();
    return data.accessToken;
  } catch (error) {
    console.error("Error fetching access token", error);
    throw error;
  }
};

const postAPI = async (url: string, data: any) => {
  const token = await fetchToken();
  return poster(url, token, data);
};

export default postAPI;